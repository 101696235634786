<script>
export default {
  props: {
    filters: {
      type: Object,
      required: true,
    },
    showByFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["on-filter-change"],
};
</script>

<template>
  <div class="pagination-controls">
    <div v-if="showByFilter" class="filter">
      <div>
        <translate>Show by</translate>
      </div>

      <b-form-select
        id="per-page-select"
        v-model="filters.perPage"
        :options="filters.pageOptions"
        class="form-select input-style pageSelect d-inline"
        @input="$emit('on-filter-change')"
      >
      </b-form-select>
    </div>

    <div style="display: flex; justify-content: flex-end">
      <b-pagination
        v-model="filters.page"
        :total-rows="filters.totalCount"
        :per-page="filters.perPage"
        first-number
        last-number
        next-class="last-el"
        prev-class="prev-el"
        ellipsis-class="ellipsis-el"
      >
      </b-pagination>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/colors.scss";
@import "@/style/breakpoints.scss";

.pagination-controls {
  /* display: grid; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 5px 0px;

  @include for-phone-only {
    justify-content: center;
  }

  & .filter {
    display: flex;
    align-items: center;
    gap: 15px;

    & span {
      @include raleway-medium;
      font-size: 14px;
      white-space: nowrap;
    }
  }
}

.pagination-controls:deep(.pageSelect) {
  @include raleway-regular;
  height: 40px;
  width: 72px;
  padding: 8px 18px 8px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 16px;

  &:focus {
    box-shadow: 0px 0px 0px 1px $brand-color;
  }

  &:focus-visible {
    box-shadow: 0px 0px 0px 1px $brand-color;
  }
}

.pagination-controls:deep(.pagination) {
  height: 40px;
  display: flex;
  flex-wrap: nowrap;
  margin: 0px;
  gap: 8px;
}

.pagination-controls:deep(.pagination > .page-item > .page-link) {
  @include raleway-regular;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 16px;
  background-color: #ffffff;
  color: #212126;
  border-radius: 16px;
  border: none;

  &:focus {
    box-shadow: 0px 0px 0px 1px $brand-color;
  }

  &:focus-visible {
    box-shadow: 0px 0px 0px 1px $brand-color;
  }
}

.pagination-controls:deep(.pagination > .page-item.disabled > .page-link) {
  background-color: #e9e8ef;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-controls:deep(.pagination > .page-item.active > .page-link) {
  background-color: $brand-color;
  color: white;
}

// previous element
.pagination-controls:deep(.pagination > .page-item.prev-el) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.pagination-controls:deep(.pagination > .page-item.prev-el > .page-link) {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  padding: 0;
  color: #212126;
}

// last element
.pagination-controls:deep(.pagination > .page-item.last-el) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.pagination-controls:deep(.pagination > .page-item.last-el > .page-link) {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  padding: 0;
  color: #212126;
}

// ellipsis element
.pagination-controls:deep(.pagination > .page-item.ellipsis-el) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-controls:deep(.pagination > .page-item.ellipsis-el > .page-link) {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  padding: 0;
  color: #212126;
  background-color: transparent;
}
</style>