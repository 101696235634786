import Vue from "vue";
import VueRouter from "vue-router";
import { RouteName } from "@/values/routeName";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../layouts/AuthLayout.vue"),
    children: [
      {
        path: "/",
        name: RouteName.Start,
        component: () => import("../views/auth/login.vue"),
        meta: {
          title: "Advy.ai - Log in",
          requiresAuth: false,
          emptyRootLayout: true,
        },
        props: true,
      },
      {
        path: "/register",
        name: RouteName.Register,
        component: () => import("../views/auth/register.vue"),
        meta: {
          title: "Advy.ai - Register",
          requiresAuth: false,
          emptyRootLayout: true,
        },
        props: true,
      },
      {
        path: "/reset-password",
        name: RouteName.ResetPassword,
        component: () => import("../views/auth/reset-password.vue"),
        meta: {
          title: "Advy.ai - Reset password",
          requiresAuth: false,
          emptyRootLayout: true,
        },
        props: true,
      },
      {
        path: "/privacy-policy",
        name: RouteName.PrivacyPolicy,
        component: () => import("@/views/legal.vue"),
        meta: {
          title: "Advy.ai - Privacy Policy",
          requiresAuth: false,
          emptyRootLayout: true,
        },
      },
      {
        path: "/service-terms",
        name: RouteName.ServiceTerms,
        component: () => import("@/views/legal.vue"),
        meta: {
          title: "Advy.ai - Terms of service",
          requiresAuth: false,
          emptyRootLayout: true,
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("../layouts/LandingLayout.vue"),
    children: [
      {
        path: "/influencer/:id/:account",
        name: RouteName.InfluencerPublic,
        component: () => import("../views/influencer-public/index.vue"),
        meta: {
          title: "Advy.ai - Influencer Overview",
          requiresAuth: false,
          emptyRootLayout: true,
        },
        props: true,
      },
    ],
  },
  {
    path: "/shop/:id",
    name: RouteName.Shop,
    component: () => import("../views/shop/index.vue"),
    meta: {
      title: "Advy.ai - Shop",
      requiresAuth: false,
      emptyRootLayout: true,
    },
    props: true,
  },

  {
    path: "/campaigns",
    name: RouteName.Campaigns,
    component: () => import("../views/campaigns/index.vue"),
    meta: {
      title: "Advy.ai - Campaigns",
      requiresAuth: true,
    },
    children: [
      {
        path: "onboarding",
        name: "onboarding",
        component: () => import("../views/onboarding/index.vue"),
        meta: {
          title: "Advy.ai - Onboarding Start",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/campaign/:id",
    name: RouteName.Campaign,
    component: () => import("../views/campaign/index.vue"),
    redirect: { name: RouteName.Description },
    meta: {
      title: "Advy.ai - Campaign",
      requiresAuth: true,
    },
    props: true,
    children: [
      {
        path: "description",
        name: RouteName.Description,
        component: () => import("../views/campaign/description/index.vue"),
        meta: {
          title: "Advy.ai - Campaign Description",
          requiresAuth: true,
        },
        children: [
          {
            path: "general",
            name: RouteName.GeneralDescription,
            component: () => import("../views/campaign/description/general.vue"),
            meta: {
              title: "Advy.ai - General campaign overview",
              requiresAuth: true,
            },
            props: true,
          },
          {
            path: "bloggers",
            name: RouteName.BloggersDescription,
            component: () => import("../views/campaign/description/bloggers.vue"),
            meta: {
              title: "Advy.ai - Bloggers campaign overview",
              requiresAuth: true,
            },
            props: true,
          },
        ],
      },
      {
        path: "bloggers",
        name: RouteName.Bloggers,
        component: () => import("../views/campaign/bloggers.vue"),
        meta: {
          title: "Advy.ai - Campaign Blogger",
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: "selection",
        name: RouteName.Selection,
        component: () => import("../views/campaign/selection.vue"),
        meta: {
          title: "Advy.ai - Campaign Selection",
          requiresAuth: true,
        },
        props: true,
      },
    ],
  },
  {
    path: "/campaign/:id/init",
    name: RouteName.Init,
    component: () => import("../views/campaign/initView.vue"),
    meta: {
      title: "Advy.ai - Campaign Description",
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: RouteName.Settings,
    component: () => import("../views/settings/index.vue"),
    meta: {
      title: "Advy.ai - Settings",
      requiresAuth: true,
    },
  },
  {
    path: "/favourites",
    name: RouteName.Favourites,
    component: () => import("../views/favourites/index.vue"),
    meta: {
      title: "Advy.ai - Favourites",
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  const authToken = localStorage.getItem("accessToken");
  const defaultNextRoute = { name: RouteName.Campaigns };

  if (to.name === RouteName.Start && to.query.sent_email === "yes") {
    next({ name: RouteName.ResetPassword, query: { step: "password-reset" } });
  } else if (to.meta.requiresAuth === false) {
    const isSkipLogin = authToken && to?.name === RouteName.Start;
    next(isSkipLogin ? defaultNextRoute : undefined);
  } else if (to?.meta?.requiresAuth && !authToken) {
    const back_url = to.fullPath;
    next({ name: RouteName.Start, query: { back_url } });
  } else if (!to?.meta?.requiresAuth && authToken) {
    next(defaultNextRoute);
  } else if (
    // @NOTE: Redirect to Default Campaign Overview type
    // From /campaign/:id/description to /campaign/:id/description/general
    to.name === RouteName.Description
  ) {
    next({ path: `${to.path}/general`, query: from.query });
  } else {
    next();
  }
});

export default router;
