<script>
import { Icon } from "@iconify/vue2";
import colors from "@/style/colors";

export default {
  name: "BaseCheckbox",
  components: {
    Icon,
  },
  props: {
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: Number,
      required: false,
      default: 20,
    },
  },
  data() {
    return {
      colors: colors,
    };
  },
  emits: ["on-check", "on-uncheck"],
};
</script>

<template>
  <div>
    <button v-if="disabled === true" class="checkbox" disabled="true">
      <Icon icon="bxs-minus-square" :width="size" :color="colors.secondaryWhiteGrey" />
    </button>

    <div v-else>
      <button v-if="checked" class="checkbox" @click.prevent="$emit('on-uncheck')">
        <Icon icon="bxs-check-square" :width="size" :color="colors.brandColor" />
      </button>

      <button v-else class="checkbox" @click.prevent="$emit('on-check')">
        <div class="checkbox-unchecked">
          <Icon icon="bx-square" :width="size" :color="colors.secondaryWhiteGrey" />
        </div>

        <Icon icon="bxs-square" :width="size" :color="colors.primaryWhite" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/index.scss";

.checkbox {
  position: relative;
  max-width: min-content;

  &-unchecked {
    position: absolute;
  }
}
</style>