import ApiService from "@/api/ApiService";
import { poll } from "@/functions/poll";
import { isEqual } from "@/functions/utils";

const api = new ApiService();

export default {
  namespaced: true,
  state: () => ({
    allAvailableChats: {},
    openedChatMessages: [],
    messagesListenerCleanupFn: null,
    error: "",
  }),
  mutations: {
    setAvailableChats(state, chatMap = {}) {
      state.allAvailableChats = chatMap;
    },
    setChat(state, chat) {
      if (!chat) return;
      state.allAvailableChats = { ...state.allAvailableChats, [chat.id]: chat };
    },
    setError(state, error) {
      state.error = error;
    },
    setCurrentChatMessages(state, messages = []) {
      if (isEqual(state.openedChatMessages, messages)) return;

      const normalizedMessages = messages.slice();
      normalizedMessages.reverse();
      state.openedChatMessages = normalizedMessages;
    },
    prependMessages(state, messages = []) {
      if (!messages.length) return;
      const data = messages.slice();
      data.reverse();
      state.openedChatMessages = [...data, ...state.openedChatMessages];
    },
    appendMessage(state, message) {
      state.openedChatMessages = [...state.openedChatMessages, message];
    },
    updateMessage(state, message) {
      const messageIndex = state.openedChatMessages.findIndex(
        (m) => m.id === message.id,
      );

      if (messageIndex < 0) {
        return;
      }

      const newMessages = state.openedChatMessages.slice();
      Object.assign(newMessages[messageIndex], message);
      state.openedChatMessages = newMessages;
    },
    setMessagesListenerCleanup(state, cleanupFn) {
      state.messagesListenerCleanupFn = cleanupFn;
    },
  },
  actions: {
    async fetchAvailableChats({ commit }) {
      const { results: chats } = await api
        .get(`/api/chat/chats/`)
        .then(({ data }) => data);

      const chatMap = chats.reduce((map, chat) => {
        map[chat.id] = chat;
        return map;
      }, {});

      commit("setAvailableChats", chatMap);
      return chats;
    },
    async sendMessage(
      { commit },
      { chatId, text = "", files = [], attachments = [] },
    ) {
      commit("setError", "");

      const payload = { message: text, files, author: "advertiser" };
      const message = await api
        .post(`/api/chat/chat/${chatId}`, payload)
        .then(({ data }) => data);
      message.files = attachments;

      commit("appendMessage", message);
      return message;
    },
    async uploadAttachmentFile({ commit }, formData) {
      return await api
        .post(`/api/chat/chat_file/`, formData)
        .then(({ data }) => data);
    },
    async markAsRead({ commit }, messageId) {
      try {
        await api
          .put(`/api/chat/message/${messageId}`)
          .then(({ data }) => data);
      } finally {
        commit("updateMessage", { id: messageId, read: true });
      }
    },
    async safeGetChat({ commit, state }, { chatId, offerId, errorMessage }) {
      if (chatId || chatId === 0) {
        const chat = state.allAvailableChats[chatId];
        if (!chat) commit("setError", errorMessage);
        return chat;
      }

      try {
        const payload = { offer: offerId, turn: "influencer" };
        const chat = await api
          .post(`/api/chat/chats/`, payload)
          .then(({ data }) => data);

        commit("setChat", chat);
        return chat;
      } catch {
        commit("setError", errorMessage);
      }
    },
    async fetchPrevMessages({ commit, state }, { id }) {
      const lastMessageId = state.openedChatMessages[0]?.id;

      if (id === undefined || lastMessageId === undefined) {
        return null;
      }

      try {
        const { results } = await api
          .get(`/api/chat/messages/${id}?before=${lastMessageId}`)
          .then(({ data }) => data);

        commit("prependMessages", results);
        return results;
      } catch {
        return null;
      }
    },
    async createMessagesListener(
      { commit, dispatch, state },
      { id, errorMessage },
    ) {
      if (id === undefined) return;
      dispatch("removeMessagesListener");

      const controller = new AbortController();
      commit("setMessagesListenerCleanup", () => controller.abort());

      const resultFn = ({ data } = {}, error) => {
        if (error || !data) {
          commit("setError", errorMessage);
          return;
        }
        commit("setCurrentChatMessages", data.results || []);
      };

      let isFirstRequest = true;

      const paramsFn = () => {
        const params = new URLSearchParams();
        const lastMessageId = state.openedChatMessages[0]?.id;

        // @NOTE: At the first request, we receive the first chunk of messages
        if (lastMessageId !== undefined && !isFirstRequest) {
          params.set("after", lastMessageId);
          isFirstRequest = false;
        }

        return params;
      };

      await poll(
        api,
        `/api/chat/messages/${id}`,
        controller,
        paramsFn,
        resultFn,
        30000,
      );
    },
    removeMessagesListener({ state, commit }) {
      if (!state.messagesListenerCleanupFn) return;
      state.messagesListenerCleanupFn?.();
      commit("setMessagesListenerCleanup", undefined);
    },
    clearChatData({ dispatch, commit }) {
      dispatch("removeMessagesListener");
      commit("setCurrentChatMessages", []);
      commit("setError", "");
    },
  },
  getters: {
    getChatInfo: (state) => (id) => state.allAvailableChats[id],
    openedChatMessages: (state) => state.openedChatMessages,
    lastMessageId: (state) => state.openedChatMessages[0]?.id ?? null,
    error: (state) => state.error,
  },
};
