import Vue from "vue";

const state = Vue.observable({
  modals: {},
});

function modalService() {
  return {
    open(id, payload = {}) {
      state.modals = {
        ...state.modals,
        [id]: { isOpen: true, payload },
      };
    },
    close(id) {
      state.modals = {
        ...state.modals,
        [id]: { isOpen: false, payload: {} },
      };
    },
    register(id) {
      state.modals = {
        ...state.modals,
        [id]: { isOpen: false, payload: {} },
      };
    },
    unregister(id) {
      const nextState = { ...state.modals };
      delete nextState[id];
      state.modals = nextState;
    },
    getState(id) {
      return state.modals[id];
    },
  };
}

export { modalService };