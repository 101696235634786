export function isEqual(a, b) {
  if (typeof a === "object" && typeof b === "object") {
    return JSON.stringify(a) === JSON.stringify(b);
  }
  return a === b;
}

export function isNull(a) {
  return a === null || a === undefined;
}

export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}