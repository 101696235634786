import ApiService from "@/api/ApiService";
import { endpoints } from "@/api/endpoints";

const api = new ApiService();

export default {
  getCurrencies() {
    return api.get(`${endpoints.common.currencies}`);
  },

  //////////////////user/////////////
  mergePdfReport(context, { id, form }) {
    return api.post(`api/adv/campaign/${id}/merge_pdf/`, form).then((r) => r.data);
  },

  //////////////////user/////////////
  getUsersAccount(context) {
    return api.get("/api/users/account/").then(response => {
      context.commit("updateAccountsList", response.data[0]);
      return response;
    });
  },

  getUsersProfilePage(context, accountId) {
    return api.get("/api/users/profilepage/" + accountId).then(response => {
      context.commit("updateUser", response.data);
      return response;
    });
  },

  getBillingTransactions(context, { id, query }) {
    const q = query ? `?${query}` : "";
    return api.get(`/api/billing/transaction/${id}${q}`).then(r => r.data);
  },

  postClientLogin(context, data) {
    return api.post("/api/users/login_client/", data).then(response => {
      const { token, user } = response.data;

      localStorage.setItem(ApiService.ACCESS_TOKEN_LOCAL_STORAGE_KEY, token.access);
      localStorage.setItem(ApiService.REFRESH_TOKEN_LOCAL_STORAGE_KEY, token.refresh);
      localStorage.setItem(ApiService.USER_ID_LOCAL_STORAGE_KEY, user);

      return response;
    });
  },

  postClientRegister(context, data) {
    return api.post("/api/users/register_client/", data).then(response => {
      return response;
    });
  },

  putDeviceToken(context, token) {
    return api.post("/api/users/device_token/", {
      "registration_id": token,
      "type": "web",
    }).then(response => {
      return response;
    });
  },

  logoutClient(context) {
    context.commit("updateUser", {});
    localStorage.removeItem(ApiService.ACCESS_TOKEN_LOCAL_STORAGE_KEY);
    localStorage.removeItem(ApiService.REFRESH_TOKEN_LOCAL_STORAGE_KEY);
    localStorage.removeItem(ApiService.USER_ID_LOCAL_STORAGE_KEY);
  },

  putPasswordReset(context, params) {
    return api.put("/api/users/password_reset/link/", params).then(response => {
      return response;
    });
  },
  getAccountData(context, id) {
    return api.get("/api/users/account_data/" + id);
  },
  putUsersAccount(context, data) {
    return api.put("/api/users/account/", data);
  },
  putProfileUpdate(context, data) {
    return api.put("/api/users/update_profile/", data);
  },
  putPasswordUpdate(context, data) {
    return api.put("/api/users/update_password/", data);
  },
  postUsersAccount(context, params) {
    return api.post("/api/users/account/", params);
  },
  deleteUser(context, query) {
    return api.delete(`/api/users/account/?${query}`);
  },
  getCardList(context, id) {
    return api.get(`/api/billing/card/${id}`).then(response => {
      context.commit("updateCardList", response.data);
      return response;
    });
  },
  postCard(context, { id, params }) {
    return api.post(`/api/billing/card/${id}`, params);
  },
  deleteCard(context, { id, params }) {
    return api.delete(`/api/billing/card/${id}`, params);
  },
  putUsersMember(context, data) {
    return api.put("/api/users/member/", data);
  },
  deleteUsersMember(context, query) {
    return api.delete(`/api/users/member/?${query}`);
  },

  /////////////////campaign////////////////
  getCampaignList(context, params) {
    // +
    return api.get(`/api/adv/campaigns_list/${params.accountId}?${params.query}`).then(response => {
      context.commit("updateCampaignList", response.data);

      return response.data;
    });
  },
  getCampaignStatuses(context) {
    return api.get("/api/adv/campaign_statuses/").then(response => {
      context.commit("updateCampaignStatuses", response.data);

      return response.data;
    });
  },
  getCampaignDescription(context, params) {
    const url = `/api/adv/campaign_description/${params.id}`;
    return api.get(`${url}${params.query ? `?${params.query}` : ""}`).then(({ data }) => {
      context.commit("updateCampaignDescription", data);
      return data;
    });
  },
  getCampaignInfluencers(context, params) {
    return api.get(`/api/adv/campaign_influencers/${params.id}?${params.query}`).then(r => {
      context.commit("updateCampaignInfluencers", r.data);
      return r.data;
    });
  },
  getCampaignResults(context, params) {
    return api.get(`/api/adv/campaign_results/${params.id}?${params.query}`).then(response => {
      context.commit("updateCampaignResults", response.data);

      return response.data;
    });
  },
  getCampaignBarters(context, params) {
    return api.get(`/api/adv/campaign_barter/${params.id}?${params.query}`).then(response => {
      context.commit("updateCampaignBarters", response.data);

      return response.data;
    });
  },
  getCampaignSelections(context, id) {
    return api.get("/api/adv/simple_selection/" + id).then(response => {
      context.commit("updateCampaignSelections", response.data);

      return response.data;
    });
  },
  getCampaign(context, campaignId) {
    return api.get("/api/adv/get_campaign/" + campaignId).then(response => {
      context.commit("updateCampaign", response.data);

      return response.data;
    });
  },
  getOfferStatuses(context) {
    return api.get("/api/adv/offer_statuses/");
  },
  getInfluencerData(context, id) {
    return api.get(`/api/adv/get_influencer_data/${id}`);
  },

  getCampaignInfluencersExcel(context, params) {
    return api.get(`/api/adv/campaign_influencers/${params.id}/download`, {}, true).then(response => {
      return response.data;
    });
  },
  getCampaignResultsExcel(context, params) {
    return api.get(`/api/adv/campaign_results/${params.id}/download?${params.query}`, {}, true).then(response => {
      return response.data;
    });
  },
  getGeoList(context, geo) {
    return api.get(`/api/common/geo/${geo}`);
  },
  getTopicsList(context) {
    return api.get(`/api/adv/topics/`);
  },
  postCampaignCreate(context, params) {
    return api.post("/api/adv/create_campaign/", params);
  },
  postCampaignFiles(context, params) {
    return api.postFormData("/api/adv/campaign_files/", params);
  },
  putCampaignData(context, params) {
    const campaignId = params.campaignId;
    delete params.campaignId;

    return api.put("/api/adv/campaign/" + campaignId, params);
  },
  setSearchCampaignName(context, name) {
    context.commit("updateSearchCampaignName", name);
  },
  setSearchCampaignDates(context, dates) {
    context.commit("updateSearchCampaignDates", dates);
  },
  setSearchInfluencerName(context, name) {
    context.commit("updateSearchInfluencerName", name);
  },
  postOfferFromSelection(context, { campaignId, username, network }) {
    return api
      .post(`/api/adv/simple_selection_register/${campaignId}`, { network, username })
      .then((r) => r.data);
  },
  postRejectFromSelection(context, params) {
    const campaignId = params.campaignId;
    const influencerId = params.influencerId;
    return api.post(`/api/adv/simple_selection_reject/${campaignId}`, { ref_id: influencerId }).then(response => {
      return response.data;
    });
  },
  removeFromSelection(_, params) {
    const offerId = params.offerId;
    return api.delete(`/api/adv/simple_selection_delete/${offerId}`).then(response => {
      return response.data;
    });
  },
  ///////////bloggers////////////
  getBlogCategories(context, params) {
    return api.get("/api/adv/blog_categories/");
  },
  getFavouriteBloggers(context) {
    return api.get("/api/adv/favourites/").then(response => {
      context.commit("updateFavouriteBloggers", response.data);
      return response.data;
    });
  },
  postBloggerToFavourites(context, params) {
    return api.post("/api/adv/favourites/add", params);
  },
  deleteBloggerFromFavourites(context, params) {
    return api.post("/api/adv/favourites/remove", params);
  },
  ///////////client cabinet////////
  async fetchInterests({ commit }, params) {
    await api
      .get("api/common/interest/")
      .then(({ data }) => {
        commit("updateInterests", data);
      })
      .catch(() => {
      });
  },
  fetchGeos(context, query) {
    if (query != "all") {
      api.get("api/common/geo/" + query).then(({ data }) => {
        context.commit("updateGeos", data);
      });
    }
  },
};
