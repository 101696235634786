<script>
import { v4 as generatedId } from "uuid";
import { Icon } from "@iconify/vue2";

export default {
  name: "BaseFormInput",
  components: { Icon },
  props: {
    label: { type: String, required: true },
    name: { type: String, required: false },
    value: { type: [String, Number], default: "" },
    type: { type: String, default: "text" },
    error: { type: Array, default: () => [false, ""] },
    validationSchema: { type: Object, required: false },
    validationStrategy: {
      type: String,
      validator: (v) => ["onBlur", "onInput"].includes(v),
      default: "onBlur",
    },
    nonDisplayedErrors: { type: Array, default: () => [] },
  },
  emits: ["focus", "blur", "input", "update:error"],
  data: () => ({
    innerValue: "",
    innerError: [false, ""],
    isShowPassword: false,
    isFocused: false,
    inputId: generatedId(),
  }),
  mounted() {
    this.$refs.inputRef.addEventListener("click", () => {
      this.$refs.inputRef.focus();
    });
  },
  computed: {
    containerClass() {
      const [isInvalid, error] = this.innerError;
      return {
        inputContainer: true,
        inputContainerFocused: this.isFocused || this.innerValue,
        inputContainerInvalid: isInvalid && !this.nonDisplayedErrors.includes(error),
      };
    },
    labelClass() {
      return { labelFocused: this.isFocused || this.innerValue };
    },
    innerType() {
      return this.type === "password" ? this.isShowPassword ? "text" : "password" : this.type;
    },
  },
  methods: {
    onFocus(e) {
      this.isFocused = true;
      this.$emit("focus", e);
    },
    onBlur(e) {
      this.isFocused = false;
      this.$emit("blur", e);
      this.validationStrategy === "onBlur" && this.validate();
    },
    handleInput(event) {
      const nextValue = event.target.value;
      this.innerValue = nextValue;
      this.$emit("input", nextValue);

      this.validationStrategy === "onBlur" && this.updateError([false, ""]);
      this.validationStrategy === "onInput" && this.validate();
    },
    validate() {
      if (!this.validationSchema) return;
      try {
        this.validationSchema.validateSync(this.innerValue);
        this.updateError([false, ""]);
      } catch (e) {
        this.updateError([true, e.errors[0] || ""]);
      }
    },
    updateError(error) {
      this.innerError = error;
      this.$emit("update:error", error);
    },
  },
  watch: {
    value: {
      handler() {
        this.innerValue = this.value;
      },
      immediate: true,
    },
    error: {
      handler() {
        this.innerError = this.error;
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <div :class="containerClass">
    <label :class="labelClass" :for="inputId">
      {{ label }}
    </label>

    <input
      :id="inputId"
      ref="inputRef"
      :name="name"
      :type="innerType"
      :value="innerValue"
      v-bind="$attrs"
      @blur="onBlur"
      @focus="onFocus"
      @input="handleInput"
    />

    <template v-if="type === 'password'">
      <button class="inputButton" type="button" @click="isShowPassword = !isShowPassword">
        <Icon v-if="isShowPassword" class="inputButtonIcon" icon="bi:eye" />
        <Icon v-else class="inputButtonIcon" icon="bi:eye-slash" />
      </button>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/colors.scss" as colors;

.inputContainer {
  position: relative;
  min-height: 60px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid colors.$secondary-white-grey;
  overflow: hidden;
  transition: border-color 0.2s;

  &Focused {
    border-color: colors.$brand-color;
  }

  &Invalid {
    border-color: colors.$primary-red;
  }

  input {
    all: unset;
    height: 100%;
    width: 100%;
    border: none;
    padding: 15px 15px 0 15px;
    font-size: 16px;
    color: colors.$primary-black;
  }

  label {
    position: absolute;
    left: 15px;
    transform-origin: left bottom;
    transition: top 0.2s, font-size 0.2s, transform 0.2s ease-out;
    color: colors.$primary-grey;
    margin: 0;
    font-size: 16px;

    &.labelFocused {
      font-size: 14px;
      transform: translateY(-100%) scale(0.8);
    }

    &:hover {
      cursor: text;
    }
  }
}

.inputButton {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  border: none;
  background: none;
  color: colors.$secondary-light-grey;
  outline-offset: 2px;
  transition: all 0.3s;

  :deep(svg) {
    width: 16px;
    height: 16px;
  }

  &:focus-visible {
    outline: revert;
  }

  &:hover, &:active {
    color: colors.$primary-grey;
  }
}
</style>
