<script>
import { mapActions, mapState } from "vuex";

import MobileHeader from "@/components/menu/MobileHeader";
import Sidebar from "@/components/menu/Sidebar";
import Loading from "@/components/ui/Loading.vue";
import BaseNotificationProvider from "@/components/ui/BaseNotificationProvider.vue";

import { app as firebaseApp } from "./firebase";
import { deleteToken, getMessaging, getToken, onMessage } from "firebase/messaging";
import { register as registerSW } from "register-service-worker";

export default {
  name: "App",
  components: {
    BaseNotificationProvider,
    MobileHeader,
    Sidebar,
    Loading,
  },
  data() {
    return {
      flag: false,
      messaging: null,
      clientToken: null,
      statusMessage: null,
    };
  },
  async created() {
    this.messaging = getMessaging(firebaseApp);
    if (this.isSubscribed()) this.clientToken = await this.getSubscribedToken();
    await this.registerServiceWorker();

    let autologin = this.$cookies.get("autologin");

    if (autologin) {
      localStorage.setItem("accessToken", autologin.access);
      localStorage.setItem("refreshToken", autologin.refresh);
      let campaignId = autologin.campaign;
      this.$cookies.remove("autologin");

      if (campaignId) {
        window.location.href = "/campaign/" + campaignId + "/bloggers";
      } else {
        window.location.href = "/campaigns";
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    ...mapActions(["getUsersAccount", "getUsersProfilePage", "putDeviceToken"]),
    onResize() {
      this.$store.commit("breakpointModule/setWindowWidth", document.body.clientWidth);
      this.$store.commit("breakpointModule/setWindowHeight", window.innerHeight);
    },
    async registerServiceWorker() {
      let subscribeToken = this.subscribeToken;
      if ("Notification" in window && navigator.serviceWorker) {
        registerSW(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
          ready(reg) {
            // subscribe to FCM
            subscribeToken(reg);
          },
          registered(reg) {
            setInterval(() => reg.update(), 1000 * 60 * 30); // 30 min checks
          },
          cached() {},
          updatefound() {},
          updated(reg) {
            document.dispatchEvent(new CustomEvent("swUpdated", { detail: reg.waiting }));
          },
          offline() {},
          error(error) {
            console.error("Error during service worker registration:", error);
          },
        });
      }
    },
    async subscribeToken(reg) {
      let vapidKey = process.env.VUE_APP_VAPIDKEY; //process.env.VAPIDKEY;
      try {
        let token = await getToken(this.messaging, {
          vapidKey,
          serviceWorkerRegistration: reg,
        });

        // send token to server
        let res = await this.sendTokenToServer(token);

        // store returned token
        this.setSubscribedToken(res);

        this.listenForegroundMessage(reg);
      } catch (err) {
        console.error(err);
        this.unsetSubscribedToken();
      }
    },
    async unsubscribeToken() {
      try {
        let unsubscribe = await deleteToken(this.messaging);
        let res = await this.removeTokenFromServer();
        await this.unsetSubscribedToken();
      } catch (err) {
        console.error(err);
      }
    },
    async listenForegroundMessage(reg) {
      if (!reg)
        reg = await navigator.serviceWorker.getRegistration(
          `${process.env.BASE_URL}firebase-messaging-sw.js`,
        );

      onMessage(this.messaging, (payload) => {
        let { notification, data } = payload;
        let notificationTitle = "Test title";
        let notificationBody = "Test body";

        if (notification && notification.title && notification.body) {
          notificationTitle = notification.title;
          notificationBody = notification.body;
        } else if (data && data.title && data.body) {
          notificationTitle = data.title;
          notificationBody = data.body;
        }

        // in window noti
        this.$notify({
          group: "test",
          title: "[Foreground] " + notificationTitle,
          text: notificationBody,
          duration: 10000,
        });

        const notificationOptions = {
          body: notificationBody,
        };
        if (reg) reg.showNotification("[Foreground] " + notificationTitle, notificationOptions);
      });
    },
    isSubscribed() {
      let ct = localStorage.getItem("clientToken");
      return !!ct;
    },
    setSubscribedToken({ data }) {
      this.clientToken = data.device_token;
      localStorage.setItem("clientToken", this.clientToken);
    },
    unsetSubscribedToken() {
      this.clientToken = null;
      localStorage.removeItem("clientToken");
    },
    async getSubscribedToken() {
      return localStorage.getItem("clientToken");
    },
    sendTokenToServer(token) {
      return this.putDeviceToken(token);
    },
    removeTokenFromServer() {
      return this.putDeviceToken("");
    },
  },
  computed: {
    ...mapState(["accountsList"]),
  },
};
</script>

<template>
  <div id="app">
    <notifications group="test" />
    <BaseNotificationProvider />

    <div v-if="!$route.name" class="main w-100 d-flex justify-content-center align-items-center">
      <Loading />
    </div>

    <div v-else-if="$route.meta.emptyRootLayout" class="main w-100">
      <router-view />
    </div>

    <div v-else-if="$route.name !== 'start'" class="w-100">
      <div class="container-fluid px-0">
        <MobileHeader />
        <div class="main row flex-nowrap no-gutters">
          <div class="col-auto px-0 d-none d-md-block">
            <Sidebar />
          </div>
          <div class="col pl-md-0 pl-3 pr-3 pb-4">
            <router-view />
          </div>
        </div>
      </div>
    </div>

    <div v-else class="main">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#app {
  width: 100%;
}

.main {
  width: 100%;
  display: flex;
  min-height: 100vh;
}
</style>
