const RouteName = {
  Start: "start",
  PrivacyPolicy: "privacy-policy",
  ServiceTerms: "service-terms",
  Register: "register",
  ResetPassword: "reset-password",

  InfluencerPublic: "influencer-public",

  Init: "init",
  Shop: "shop",
  Settings: "settings",
  Favourites: "favourites",
  Campaign: "campaign",
  Campaigns: "campaigns",
  Selection: "selection",
  Bloggers: "bloggers",
  Description: "description",
  GeneralDescription: "general-description",
  BloggersDescription: "bloggers-description",
};

Object.freeze(RouteName);

export { RouteName };
