<script>
import { Icon } from "@iconify/vue2";
import colors from "@/style/colors";
import Loading from "@/components/ui/Loading.vue";

export default {
  props: {
    title: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    icon: { type: String, default: "" },
    iconPosition: {
      validator: (value) => ["left", "right"].includes(value),
      default: "left",
    },
    iconOnly: { type: Boolean, default: false },
    variant: {
      validator: (value) => ["default", "outline"].includes(value),
      default: "default",
    },
    colorMode: {
      validator: (value) => {
        return ["primary", "secondary", "light", "tertiary"].includes(value);
      },
      required: false,
      default: "primary",
    },
    customStyle: { type: Object, required: false },
    rounded: { type: Boolean, default: false },
  },
  data() {
    return {
      colors: colors,
    };
  },
  components: {
    Loading,
    Icon,
  },
  computed: {
    colorClass() {
      switch (this.colorMode) {
        case "primary":
          return "primary";
        case "secondary":
          return "secondary";
        case "light":
          return "light";
        case "tertiary":
          return "tertiary";
        default:
          return "primary";
      }
    },
    buttonClass() {
      return {
        iconOnly: this.iconOnly,
        [this.colorClass]: true,
        "button": true,
        "button--loading": this.loading,
        [`button--${this.variant}`]: true,
      };
    },
  },
  emits: ["click"],
};
</script>

<template>
  <button
    :class="buttonClass"
    :disabled="disabled && !loading"
    :style="{
      borderRadius: rounded ? '40px' : '16px',
      ...customStyle,
    }"
    v-bind="$attrs"
    @click="$emit('click')"
  >
    <template v-if="loading">
      <Loading />
    </template>
    <template v-else>
      <Icon v-if="icon && iconPosition === 'left'" :icon="icon" class="icon" />
      <slot name="icon-prepend"></slot>

      <span v-if="iconOnly === false" class="title">
      {{ title }}
      </span>

      <Icon v-if="icon && iconPosition === 'right'" :icon="icon" class="icon" />
      <slot name="icon-append"></slot>
    </template>
  </button>
</template>

<style lang="scss" scoped>
@use "@/style/fonts.scss" as fonts;
@use "@/style/breakpoints.scss" as breakpoints;
@use "@/style/colors.scss" as colors;

.button {
  @include fonts.raleway-semibold;
  width: auto;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 0 20px;
  border: none;
  color: colors.$primary-white;
  white-space: nowrap;
  transition: all 0.3s;

  @include breakpoints.for-big-desktop-up {
    height: 48px;
    justify-content: center;
  }

  &:focus,
  &:active,
  &:focus-visible,
  &:active:focus,
  &.active:focus {
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
  }

  & .title {
    @include fonts.fontSize-small;
    flex-grow: 1;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include breakpoints.for-big-desktop-up {
      @include fonts.fontSize-big;
    }
  }

  & .icon {
    height: 16px;
    width: 16px;

    @include breakpoints.for-big-desktop-up {
      height: 24px;
      width: 24px;
    }
  }
}

.iconOnly {
  @include fonts.raleway-semibold;
  width: 44px;
  height: 44px;
  justify-content: center;
  padding: 0;

  @include breakpoints.for-big-desktop-up {
    height: 48px;
    width: 48px;
    justify-content: center;
  }
}

.primary {
  background-color: colors.$brand-color;

  &:not(:disabled):active,
  &:not(:disabled):hover {
    background-color: colors.$brand-color-dark;
  }
}

.secondary {
  background-color: colors.$primary-black;

  &:not(:disabled):active,
  &:not(:disabled):hover {
    background-color: colors.$secondary-grey;
  }
}

.light {
  background-color: colors.$primary-background;
  color: colors.$primary-black;

  &:not(:disabled):active,
  &:not(:disabled):hover {
    background-color: colors.$primary-background-dark;
  }
}

.tertiary {
  background-color: colors.$primary-white;
  color: colors.$primary-black;

  &:not(:disabled):active,
  &:not(:disabled):hover {
    background-color: colors.$primary-lavender-dark;
  }
}

.button.button--outline {
  &.primary {
    border: 1px solid colors.$brand-color;
    color: colors.$brand-color;
    background: transparent;

    &:not(:disabled):active,
    &:not(:disabled):hover {
      border: 1px solid colors.$brand-color-dark;
      color: colors.$brand-color-dark;
      background: transparent;
    }
  }

  &.secondary {
    border: 1px solid colors.$primary-black;
    color: colors.$primary-black;
    background: transparent;

    &:not(:disabled):active,
    &:not(:disabled):hover {
      border: 1px solid colors.$secondary-grey;
      color: colors.$secondary-grey;
      background: transparent;
      opacity: 0.75;
    }
  }
}

.button.button--loading {
  pointer-events: none;
  cursor: default;
  justify-content: center;

  :deep(svg) {
    color: colors.$primary-white !important;
    height: 80px !important;
    width: 100px !important;
  }
}
</style>
