<script>
import { modalService } from "@/services/modal.service";

export default {
  name: "BaseProvidedModal",
  props: {
    id: { type: String, required: true },
    title: { type: String, required: false },
    size: { type: String, required: false },
    hideHeader: { type: Boolean, default: true },
    hideFooter: { type: Boolean, default: true },
  },
  computed: {
    state() {
      return modalService().getState(this.id) ?? { isOpen: false, payload: {} };
    },
  },
  created() {
    modalService().register(this.id);
  },
  beforeDestroy() {
    modalService().unregister(this.id);
  },
  methods: {
    open(payload = {}) {
      modalService().open(this.id, payload);
    },
    close() {
      modalService().close(this.id);
    },
    onHide(e) {
      if (e.trigger === "backdrop" || e.trigger === "esc") {
        this.onClose();
      }
    },
    onClose() {
      this.$emit("hide");
      this.close();
    },
  },
  watch: {
    "state.isOpen"(value) {
      this.$nextTick(() => {
        value ? this.$bvModal.show(this.id) : this.$bvModal.hide(this.id);
      });
    },
  },
  emits: ["hide"],
};
</script>

<template>
  <b-modal
    v-if="state.isOpen"
    :id="id"
    :hide-footer="hideFooter"
    :hide-header="hideHeader"
    :size="size"
    :title="title"
    centered
    scrollable
    @close="onClose"
    @hide="onHide"
  >
    <slot v-bind:payload="state.payload" />

    <template #modal-footer>
      <slot name="footer"></slot>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
@use "@/style/fonts.scss" as fonts;
@use "@/style/colors.scss" as colors;

:deep(.modal > .modal-dialog > .modal-content) {
  border-radius: 16px;
  background-color: colors.$primary-background;
}

:deep(.modal > .modal-dialog > .modal-content > .modal-header) {
  min-height: 50px;
  border-bottom: none;
  justify-content: center;

  & .modal-title {
    @include fonts.raleway-semibold;
    text-align: center;
    font-size: 28px;
    line-height: 30px;
  }

  & .close {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

:deep(.modal > .modal-dialog > .modal-content > .modal-header > .close) {
  height: 50px;
  width: 50px;
  padding: 0;
  color: colors.$secondary-light-grey;
}

:deep(.modal > .modal-dialog > .modal-content > .modal-body) {
  padding-top: 0;
}

:deep(.modal > .modal-dialog > .modal-content > .modal-footer) {
  padding: 20px;
}
</style>