<script>
import { createNamespacedHelpers, mapActions } from "vuex";
import { v4 as generatedId } from "uuid";
import { Icon } from "@iconify/vue2";
import { TELEGRAM_LINK } from "@/config";
import { ModalError } from "@/components/menu";
import {
  BaseButton,
  BaseDropdown,
  BaseModalContainer,
  BaseStepProgressBar,
  BaseViewButton,
  Loading,
} from "@/components/ui";
import BaseProvidedModal from "@/components/ui/BaseProvidedModal.vue";
import { ModalName } from "@/values/modalName";
import { modalService } from "@/services/modal.service";
import BaseFormDropdown from "@/components/ui/form/BaseFormDropdown.vue";
import BaseFormInput from "@/components/ui/form/BaseFormInput.vue";
import { DefaultNetworkAsset, NetworkAssetMap } from "@/values/network";

const { mapActions: mapCampaignActions } = createNamespacedHelpers("campaignModule");

export default {
  name: "CampaignCreationModal",
  components: {
    BaseProvidedModal,
    BaseButton,
    BaseDropdown,
    BaseModalContainer,
    BaseStepProgressBar,
    BaseViewButton,
    BaseFormDropdown,
    BaseFormInput,
    Icon,
    Loading,
    ModalError,
  },
  props: {
    isSmallerScreen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ModalName,
      state: "isLoading",
      steps: [1, 2, 3],
      currentStep: 1,
      formats: [],
      networks: [],
      format: null,
      network: null,
      campaignName: "",
      budget: "",
      currencies: null,
      currency: null,
      errorModalId: generatedId(),
    };
  },
  created() {
    Promise.all([this.getCurrencyList(), this.getCampaignFormatList()])
      .then(() => (this.state = "isSuccess"))
      .catch(() => this.showErrorModal());
  },
  methods: {
    ...mapActions({ getCurrencies: "getCurrencies" }),
    ...mapCampaignActions({
      getCampaignFormats: "getCampaignFormats",
      createCampaign: "createCampaign",
    }),
    getCurrencyList() {
      this.getCurrencies().then((response) => {
        this.currencies = response.data;
        this.currency = this.currencies[0];
      });
    },
    getCampaignFormatList() {
      this.getCampaignFormats().then((data) => {
        this.formats = data;
      });
    },
    goStepBack() {
      this.currentStep -= 1;
    },
    handleContinueBtnClick() {
      this.currentStep += 1;
    },
    selectFormat(item) {
      this.format = item;
    },
    selectNetwork(item) {
      this.network = item;
    },
    async handleSubmit() {
      this.state = "isLoading";

      const data = {
        budget: this.budget,
        currency: this.currency.code,
        kind: this.format.key,
        name: this.campaignName,
        network: this.network.network,
      };

      try {
        const campaignId = await this.createCampaign(data).then((r) => r.data.id);
        this.state = "isSuccess";
        this.currentStep = 1;
        this.campaignName = "";
        this.budget = "";
        this.format = "";
        this.network = "";
        this.$emit("on-hide");

        modalService().close(ModalName.CampaignCreationModal);

        await this.$router.push({
          name: "selection",
          params: { id: campaignId },
          query: { ...this.$route.query }
        });
      } catch {
        this.showErrorModal();
      }
    },
    getNetworksInOrder(list) {
      return [...list].sort((a, b) => a.order - b.order);
    },
    getNetworkAsset(network) {
      return NetworkAssetMap[network] ?? DefaultNetworkAsset;
    },
    getNetworkDescription(network) {
      // TODO: hack for gettext
      if (network == "instagram") {
        return this.$gettext(`Only instagram oriented traffic`);
      } else if (network == "telegram") {
        return this.$gettext(`Only telegram oriented traffic`);
      } else if (network == "youtube") {
        return this.$gettext(`Only youtube oriented traffic`);
      } else if (network == "tiktok") {
        return this.$gettext(`Only tiktok oriented traffic`);
      }
    },
    showErrorModal() {
      this.$bvModal.show(this.errorModalId);
    },
    handleChallengeBtnClick() {
      window.open(TELEGRAM_LINK, "_blank");
    },
  },
  computed: {
    formattedCurrency() {
      if (this.currency) {
        return this.currency.code + " " + this.currency.symbol;
      }
    },
  },
  emits: ["on-hide"],
};
</script>

<template>
  <BaseProvidedModal
    :id="ModalName.CampaignCreationModal"
    :hideHeader="false"
    :isBackgroundWhite="true"
    size="lg"
    @hide="$emit('on-hide')"
  >
    <Loading v-if="state === 'isLoading'" />
    <ModalError :modalId="errorModalId" @cancel="$emit('on-hide')" @on-hide="$emit('on-hide')" />

    <div v-if="state === 'isSuccess'" class="wrapper">
      <div class="wrapper-top">
        <div class="d-flex flex-column align-items-center gap-3">
          <h2 class="title">
            <translate>Create campaign</translate>
          </h2>

          <BaseStepProgressBar :currentStep="currentStep" :steps="steps" />
        </div>

        <div v-if="currentStep === 1" class="content">
          <span class="subtitle"><translate>Add campaign name and maximum budget:</translate></span>

          <div class="inputs">
            <BaseFormInput v-model="campaignName" :label="$gettext('Campaign name')" />

            <div class="inputs-multiple">
              <BaseFormInput
                v-model="budget"
                :label="$gettext('Maximum budget')"
                type="number"
              />

              <BaseFormDropdown :value="formattedCurrency">
                <b-dropdown-item
                  v-for="item in currencies"
                  :key="item.code"
                  @click.prevent="currency = item"
                >
                  {{ item.code }} {{ item.symbol }}
                </b-dropdown-item>
              </BaseFormDropdown>
            </div>

            <span class="text-secondary caption">
              <translate>*minimum budget - 100$</translate>
            </span>
          </div>
        </div>

        <div v-if="currentStep === 2" class="content">
          <span class="subtitle"><translate>Choose campaign format:</translate></span>

          <div class="tile">
            <div
              v-for="item in formats"
              :key="item.id"
              :class="{ tileItemSelected: format && item.id === format.id }"
              class="tileItem"
              @click="selectFormat(item)"
            >
              <Icon :icon="item.icon" class="tileItem-icon" />

              <div class="d-flex flex-column gap-1">
                <span class="tileItem-title">{{ item.name }}</span>
                <span class="tileItem-description">
                  {{ item.description }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="currentStep === 3" class="content">
          <span v-if="format.networks.length" class="subtitle">
            <translate>Choose social media platform:</translate>
          </span>

          <div v-if="format.networks.length === 0" class="challenge">
            <span class="subtitle"><translate>To create challenge contacts us</translate></span>
            <BaseButton
              :rounded="true"
              :title="$gettext('Message')"
              icon="bxl-telegram"
              @click="handleChallengeBtnClick"
            />
          </div>

          <div class="tile tileNetworks">
            <div
              v-for="item in getNetworksInOrder(format.networks)"
              :key="item.id"
              :class="{
                tileItemSelected: network && item.id === network.id,
                disabled: item.active === false,
              }"
              class="tileItem tileItem-network"
              @click="selectNetwork(item)"
            >
              <Icon
                v-if="network && item.id === network.id || !getNetworkAsset(item.network).image"
                :icon="getNetworkAsset(item.network).icon"
                class="tileItem-icon"
              />
              <img
                v-else
                :alt="item.network"
                :src="getNetworkAsset(item.network).image"
                class="tileItem-image"
              />

              <div class="d-flex flex-column gap-1">
                <span class="tileItem-title">
                  {{ item.network | capitalize }}
                </span>
                <span class="tileItem-description">
                  {{ getNetworkDescription(item.network) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="controls">
        <button
          v-if="currentStep !== 1"
          :class="{ borderlessSmaller: isSmallerScreen }"
          :disabled="currentStep === 1"
          class="borderlessBtn"
          @click.prevent="goStepBack"
        >
          <Icon class="borderlessBtn-icon" icon="bx:chevron-left" />
          <span class="borderlessBtn-text">
            <translate>Back</translate>
          </span>
        </button>

        <BaseViewButton
          v-if="currentStep === 1"
          :class="{ continueBtn: !isSmallerScreen }"
          :disabled="!campaignName || !budget"
          :text="$gettext('Continue')"
          class="controls-btn"
          @on-click="handleContinueBtnClick"
        />

        <BaseViewButton
          v-else-if="currentStep === 2"
          :class="{ continueBtn: !isSmallerScreen }"
          :disabled="!format"
          :text="$gettext('Continue')"
          class="controls-btn"
          @on-click="handleContinueBtnClick"
        />

        <BaseViewButton
          v-else-if="currentStep === 3 && format.networks.length"
          :class="{ continueBtn: !isSmallerScreen }"
          :disabled="!network"
          :text="$gettext('Continue')"
          class="controls-btn"
          @on-click="handleSubmit"
        />
      </div>
    </div>
  </BaseProvidedModal>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/colors.scss";
@import "@/style/breakpoints.scss";

.background {
  background-color: magenta;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 60px;
  padding: 0px 20px 20px 20px;

  @include for-phone-only {
    padding: 0px 0px 10px 0px;
  }

  &-top {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    gap: 60px;

    @include bootstrap-large-lower-boundary {
      gap: 30px;
    }
  }
}

.title {
  @include raleway-bold;
  font-size: 24px;
  line-height: 28px;
  color: $primary-black;
  margin: 0;

  @include for-phone-only {
    font-size: 18px;
  }
}

.subtitle {
  @include raleway-medium;
  font-size: 16px;
  color: $primary-black;

  @include for-phone-only {
    font-size: 14px;
  }
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &-multiple {
    display: flex;
    gap: 15px;
  }
}

.tile {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @include bootstrap-large-lower-boundary {
    grid-template-columns: repeat(2, 1fr);
  }

  &Networks {
    @include for-phone-only {
      grid-template-columns: 1fr;
    }
  }
}

.tileItem {
  min-width: 170px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $primary-background;
  border-radius: 16px;
  padding: 20px;
  color: $primary-black;
  cursor: pointer;

  @include for-phone-only {
    padding: 16px;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &-network {
    @include for-phone-only {
      display: grid;
      grid-template-columns: 24px 1fr;
      align-items: center;
      gap: 16px;
      height: 92px;
    }
  }

  &-icon {
    width: 24px;
    height: 24px;
    color: $brand-color;
  }

  &-image {
    width: 24px;
    height: 24px;
  }

  &-title {
    @include raleway-semibold;
    font-size: 16px;
    line-height: 25px;
  }

  &-description {
    @include raleway-regular;
    font-size: 12px;
    line-height: 14px;
    opacity: 0.5;
  }

  &Selected {
    background-color: $brand-color;
    color: $primary-white;

    .tileItem-icon {
      color: $primary-white;
    }
  }
}

.challenge {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.controls {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  &-btn {
    grid-column: 2 / 3;
  }
}

.borderlessBtn {
  width: max-content;
  display: flex;
  align-items: center;
  padding: 0;
  gap: 5px;
  color: $primary-black;

  &-icon {
    height: 20px;
    width: 20px;
  }

  &-text {
    @include raleway-semibold;
    font-size: 16px;

    @include for-phone-only {
      font-size: 14px;
    }
  }

  &:disabled {
    opacity: 0.3;
  }
}

.continueBtn {
  width: 280px;
  height: 56px;
  font-size: 16px;
  grid-column: 2 / 3;
  justify-self: flex-end;
}

.caption {
  margin-left: 15px;
}
</style>
