import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import { INITIAL_BANNERS_VISIBILITY_STATE, LOCAL_STORAGE_KEYS } from "@/config";

import privacyPolicyModule from "@/store/modules/privacyPolicyModule";
import influencerPublicModule from "@/store/modules/influencerPublicModule";
import shopModule from "@/store/modules/shopModule";
import campaignModule from "@/store/modules/campaignModule";
import chatModule from "@/store/modules/chatModule";
import influencerSearchModule from "@/store/modules/influencerSearchModule";
import financeModule from "@/store/modules/financeModule";
import breakpointModule from "@/store/modules/breakpointModule";
import commentsModule from "@/store/modules/commentsModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    campaignModule,
    chatModule,
    financeModule,
    influencerPublicModule,
    influencerSearchModule,
    privacyPolicyModule,
    shopModule,
    breakpointModule,
    commentsModule,
  },

  state: {
    user: null,
    accountId: null,
    cardList: [],
    userAccountsList: [],
    accountsList: [],
    campaignList: [],
    campaignTotalCount: 0,
    campaignStatuses: [],
    campaign: null,
    campaignSettings: null,
    campaignDescription: null,
    campaignInfluencers: [],
    campaignInfluencersTotal: 0,
    campaignResults: [],
    campaignResultsTotal: 0,
    campaignBarters: [],
    campaignSelections: [],
    campaignSelectionsScore: null,
    interests: [],
    favouriteBloggers: [],
    geos: [],
    theme: "red",
    // search filters
    searchCampaignName: null,
    searchCampaignDates: null,
    searchInfluencerName: null,
    bannersVisibilityState:
      JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEYS.bannersVisibilityState)) ||
      INITIAL_BANNERS_VISIBILITY_STATE,
  },
  getters: {
    isCurrentUserAdmin: (state) => Boolean(state.user?.is_admin),
    interests: (state) => state.interests,
    geos: (state) => state.geos,
    campaignDescription: (state) => state.campaignDescription,
  },
  mutations,
  actions,
});
