<script>
import { mapActions } from "vuex";
import { Icon } from "@iconify/vue2";
import { TELEGRAM_LINK } from "@/config";

import { BaseButton, BaseLogo } from "@/components/ui";
import { BaseMenu } from "@/components/menu";
import { CampaignCreationModal } from "@/components/campaign-creation";
import { RouteName } from "@/values/routeName";
import { modalService } from "@/services/modal.service";
import { ModalName } from "@/values/modalName";

export default {
  name: "Sidebar",
  components: {
    BaseButton,
    BaseMenu,
    BaseLogo,
    CampaignCreationModal,
    Icon,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      bloggerItems: [],
      currentRoute: null,
      campaignCreationModalId: "campaignCreationMobileModal",
      isCampaignCreationModalDisplayed: false,
      telegramLink: TELEGRAM_LINK,
      isCollapsed: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", () => (this.windowWidth = window.innerWidth));
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => (this.windowWidth = window.innerWidth));
  },
  computed: {
    isSmallerScreen() {
      return this.windowWidth <= 991;
    },
  },
  watch: {
    "$route.name": {
      handler(value) {
        this.currentRoute = value;
      },
    },
    isSmallerScreen: {
      handler(value) {
        if (value) this.isCollapsed = true;
      },
    },
  },
  methods: {
    ...mapActions(["postCampaignCreate"]),
    createCampaign($event) {
      let campaignData = {};

      this.postCampaignCreate(campaignData).then((response) => {
        if (response.status === 201) {
          const campaignId = response.data.id;

          this.$router.push({
            name: RouteName.Selection,
            params: { id: campaignId },
          });
        } else {
          alert(this.$gettext("Some error happened, please try again"));
          this.scene = "final";
        }
      });
    },
    openCampaignCreationModal() {
      modalService().open(ModalName.CampaignCreationModal);
    },
    toggleSidebarCollapsibility() {
      this.isCollapsed = this.isCollapsed ? false : true;
    },
  },
};
</script>

<template>
  <div :class="{ collapsed: isCollapsed }" class="sidebar">
    <div class="logoContainer">
      <router-link to="/campaigns">
        <BaseLogo :isCollapsed="isCollapsed" />
      </router-link>

      <button @click.prevent="toggleSidebarCollapsibility">
        <Icon v-if="isCollapsed" class="toggleBtn" icon="bx-chevrons-right" />
        <Icon v-else class="toggleBtn" icon="bx-chevrons-left" />
      </button>
    </div>

    <div class="navigationContainer">
      <BaseButton
        :iconOnly="isCollapsed"
        :rounded="true"
        :title="$gettext('Create a campaign')"
        icon="bx-plus"
        @click="openCampaignCreationModal"
      />

      <BaseMenu :isCollapsed="isCollapsed" />
    </div>

    <div v-if="!isCollapsed">
      <div class="bannerHelp">
        <h3 class="bannerHelp-title">
          <translate>Помощь</translate>
        </h3>
        <span class="bannerHelp-text">
          <translate> Возникли вопросы по работе с сервисом?</translate>
        </span>
        <a :href="telegramLink" class="bannerHelp-btn" target="_blank">
          <translate>Написать</translate>
        </a>
      </div>
    </div>

    <!-- CAMPAIGN CREATION -->
    <CampaignCreationModal :isSmallerScreen="isSmallerScreen" />
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/breakpoints.scss";
@import "@/style/colors.scss";

.sidebar {
  width: 215px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 0px 20px;
  transition: width 0.3s ease;

  @include for-big-desktop-up {
    width: 300px;
    padding: 0px 24px 0px 20px;
  }
}

.collapsed {
  width: 119px;
  padding-right: 55px;

  & .navigationContainer {
    width: 44px;

    @include for-big-desktop-up {
      width: 48px;
    }
  }
}

.toggleBtn {
  width: 32px;
  height: 32px;
  color: $secondary-light-grey;
}

.logoContainer {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 13px;
  margin: 24px 0px 0px 0px;

  @include for-big-desktop-up {
    height: 60px;
    margin: 24px 0px 0px 0px;
  }
}

.navigationContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.bannerHelp {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 18px;
  color: $primary-white;
  padding: 20px 16px;
  background-color: $primary-white;

  &-title {
    @include raleway-bold;
    color: $secondary-grey;
    font-size: 20px;
    line-height: 24px;

    @include for-big-desktop-up {
      font-size: 32px;
      line-height: 36px;
    }
  }

  &-text {
    @include raleway-regular;
    @include fontSize-small;
    color: $primary-grey;
    text-align: center;

    @include for-big-desktop-up {
      font-size: 16px;
      line-height: 25px;
    }
  }

  &-btn {
    @include raleway-semibold;
    @include fontSize-small;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    border: 0px;
    color: $primary-black;
    background-color: $primary-background;
    margin-top: 20px;

    @include for-big-desktop-up {
      height: 48px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>