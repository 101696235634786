<script>
import colors from "@/style/colors";
import { Icon } from "@iconify/vue2";

const Status = Object.freeze({
  Completed: "completed",
  Accepted: "accepted",
  Rejected: "rejected",
  Default: "default",
});

const StatusOptionMap = {
  [Status.Completed]: { className: "green", canDisplayArrow: false },
  [Status.Accepted]: { className: "green", canDisplayArrow: false },
  [Status.Rejected]: { className: "red", canDisplayArrow: false },
  [Status.Default]: { className: "blue", canDisplayArrow: true },
};

export default {
  components: { Icon },
  props: {
    status: { type: String, required: true },
    status_translate: { type: String, default: Status.Default },
    arrow: { type: Boolean, default: false },
    arrowDir: { type: String, validator: (v) => ["up", "down"].includes(v), default: "up" },
  },
  data: () => ({
    colors: colors,
  }),
  computed: {
    validatedStatus() {
      return Object.values(Status).includes(this.status) ? this.status : Status.Default;
    },
    statusOption() {
      return StatusOptionMap[this.validatedStatus];
    },
    isDisplayArrow() {
      return this.arrow && this.statusOption.canDisplayArrow;
    },
    arrowDirClassName() {
      return this.isDisplayArrow ? this.arrowDir : "";
    },
  },
};
</script>

<template>
  <div class="labelContainer">
    <span :class="['label', statusOption.className, arrowDirClassName]">
      <Icon
        v-if="arrow && statusOption.canDisplayArrow"
        :class="['icon', arrowDirClassName]"
        icon="bx:left-arrow-alt"
      />

      {{ status_translate }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/breakpoints.scss";

.labelContainer {
  width: min-content;
  display: flex;
  align-items: center;
}

.label {
  @include raleway-medium;
  display: flex;
  align-items: center;
  gap: 4px;
  height: 22px;
  width: max-content;
  max-width: max-content;
  border-radius: 20px;
  font-size: 10px;
  padding: 0 8px;
  border: 0;
  white-space: nowrap;
  vertical-align: middle;

  @include for-big-desktop-up {
    font-size: 12px;
  }

  &.blue {
    background: #d7e5fc;
    color: #367bf2;

    &.up {
      background: #eff5fe;
      color: #68a0ff;
    }
  }

  &.green {
    background: #d6f4de;
    color: #34c759;
  }

  &.red {
    background: #fbd9e4;
    color: #ec407a;
  }
}

.icon {
  width: 16px;
  height: 16px;

  &.up {
    transform: rotate(90deg);
  }

  &.down {
    transform: rotate(-90deg);
  }
}
</style>