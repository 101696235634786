<script>
import { Icon } from "@iconify/vue2";
import colors from "@/style/colors";

export default {
  components: {
    Icon,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      colors: colors,
    };
  },
  mounted() {
    this.items = [
      {
        title: this.$gettext("Campaigns"),
        icon: "bx-trending-up",
        done: false,
        route: "campaigns",
        subdomains: ["campaigns", "campaign"],
      },
      {
        title: this.$gettext("Favourites"),
        icon: "bx:bookmark",
        done: false,
        route: "favourites",
        subdomains: ["favourites"],
      },
      {
        title: this.$gettext("Settings"),
        icon: "bx-cog",
        done: false,
        route: "settings",
        subdomains: ["settings"],
      },
      {
        title: this.$gettext("Information"),
        icon: "bx-info-circle",
        done: false,
        url: "https://advy.ai",
      },
    ];
  },
  methods: {
    activeTab(subdomains) {
      const route = this.$route?.matched?.[0]?.name;

      return subdomains.includes(route);
    },
  },
  emits: ["on-click"],
};
</script>

<template>
  <ul class="nav">
    <li v-for="item in items" :key="item.icon">
      <router-link
        :to="{ name: item.route }"
        v-if="item.route"
        class="nav-item"
        :class="{ 'active-tab': activeTab(item.subdomains) }"
        @click.native="$emit('on-click')"
      >
        <Icon :icon="item.icon" class="icon" />
        <span v-if="!isCollapsed">{{ item.title }}</span>
        <Icon
          v-if="activeTab(item.subdomains) && !isCollapsed"
          icon="bxs-right-arrow"
          :color="colors.primaryGrey"
          class="triangle-arrow-icon"
        />
      </router-link>

      <a v-if="item.url" :href="item.url" target="_blank" class="nav-item">
        <Icon :icon="item.icon" class="icon" />
        <span v-if="!isCollapsed">{{ item.title }}</span>
      </a>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/colors.scss";
@import "@/style/breakpoints.scss";

.nav {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.nav-item {
  @include raleway-semibold;
  @include fontSize-normal;
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  background-color: transparent;
  gap: 10px;
  border-radius: 16px;
  cursor: pointer;
  color: $primary-grey;

  @include for-big-desktop-up {
    @include fontSize-big;
    height: 48px;
  }

  & svg {
    color: $primary-grey;
  }

  & span {
    flex: 1;
  }
}

.active-tab {
  background-color: #ffffff;
  color: $primary-black;

  & svg {
    color: $primary-black;
  }
}

.icon {
  width: 20px;
  height: 20px;

  @include for-big-desktop-up {
    width: 24px;
    height: 24px;
  }
}

.triangle-arrow-icon {
  width: 8px;
  height: 8px;
}
</style>