<template>
  <input
    :disabled="disabled"
    :value="value"
    class="form-control base-input"
    type="text"
    v-bind="$attrs"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: String, default: "" },
  },
  emits: ["input"],
};
</script>

<style lang="scss" scoped>
@use "@/style/fonts";

.base-input {
  @include fonts.raleway-regular;
  font-size: 16px;
  line-height: 25px;
  padding: 10px 15px;
  margin: 0;
  height: 44px;
  color: #27292c;
  border-radius: 12px;
  border: 1px solid #cfd4d9;
  transition: all 200ms;

  &::placeholder {
    color: #aeb3b9;
  }

  &:focus-visible {
    border-color: #825af9;
  }
}
</style>
