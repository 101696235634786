const NetworkType = {
  Instagram: "instagram",
  Telegram: "telegram",
  Youtube: "youtube",
  Tiktok: "tiktok",
};

Object.freeze(NetworkType);

const DefaultNetworkAsset = {
  name: "",
  icon: "bi:globe",
  image: "",
  getLink: () => '',
};

Object.freeze(DefaultNetworkAsset);

const NetworkAssetMap = {
  [NetworkType.Instagram]: {
    name: "Instagram",
    icon: "bxl-instagram-alt",
    image: require(`@/assets/svgs/instagram.svg`),
    getLink: (username) => `https://instagram.com/${username}`,
  },
  [NetworkType.Telegram]: {
    name: "Telegram",
    icon: "bxl-telegram",
    image: require(`@/assets/svgs/telegram.svg`),
    getLink: (username) => `https://t.me/${username}`,
  },
  [NetworkType.Tiktok]: {
    name: "Tiktok",
    icon: "bxl-tiktok",
    image: require(`@/assets/svgs/tiktok.svg`),
    getLink: (username) => `https://tiktok.com/@${username}`,
  },
  [NetworkType.Youtube]: {
    name: "Youtube",
    icon: "bxl-youtube",
    image: require(`@/assets/svgs/youtube.svg`),
    getLink: (username) => `https://youtube.com/@${username}`,
  },
};


export { NetworkType, DefaultNetworkAsset, NetworkAssetMap };
